
import { Vue, Component } from "vue-property-decorator";
import {
  readPatientWound,
  readPatient,
  readTask,
  readVisitDate,
  readPatientID,
  readWoundTasks,
  readMeasurements,
  readWoundPerspectives,
  readPictures,
  readPicturesURL,
  readComment,
  readShowComment,
  readLanguage
} from "@/store/admin/getters";
import { api } from "@/api";
import {
  commitChangeMeasurement,
  commitMeasurementsClear,
  commitSetMeasurement,
  commitSetPicturesClear,
  commitSetPicture,
  commitDeleteMeasurements,
  commitSetPictureURLClear,
  commitSetComment,
  commitSetShowComment,
  commitDeletePicture,
  commitSwitchLanguage
} from "@/store/admin/mutations";
import {
  IMeasurementProfile,
  IMeasurementUpdate,
  IPicturesProfile,
  PictureHelper,
} from "@/interfaces";
import { readToken } from "@/store/main/getters";
import {
  dispatchGetPictureURL,
  dispatchDeletePicture,
} from "@/store/admin/actions";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

@Component
export default class WoundVisit extends Vue {
  public editValueChar = "";
  public mesEditValue = "";
  public editValueInt = -1;
  public editValueBool: boolean = false;
  public editDate = new Date();
  public editMesID = -1;
  public editTask = -1; //Always a number
  public editType = "";
  public editMesLabel = "The measurement type is: ";
  public dialogMesEdit = false;
  public checked = false;
  public mesDescription = "";
  public urlArray = [];
  public dialogPicture = false;
  public dialogImage = -1;
  public redo = 0;
  // public path = "";
  // public apiURL = "../../../../../backend/app/";
  public items_per_page = [
    25,
    50,
    100,
    { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 },
  ];
  public pagination = {
    rowsPerPage: 25,
  };
  public headers = [
    {
      text: "Task Description",
      sortable: true,
      value: "description",
      align: "left",
    },
    {
      text: "Data",
      sortable: false,
      value: "value",
      align: "left",
    },
    {
      text: "Type",
      sortable: false,
      value: "type",
      align: "left",
    },
  ];

  public prePostProces(prepostproces: boolean) {
    if(prepostproces) return true;
    return false;
  }
  get wound() {
    return readPatientWound(this.$store);
  }

  get windowHeight() {
    return window.innerHeight - 100 + "px";
  }

  get windowWidth() {
    return window.innerWidth - 100 + "px";
  }

  get showComment() {
    return readShowComment(this.$store);
  }
  get comment() {
    return readComment(this.$store).value_char;
  }
  get patient() {
    return readPatient(this.$store)(readPatientID(this.$store));
  }

  get day() {
    return readVisitDate(this.$store);
  }
  get tasks() {
    return readWoundTasks(this.$store);
  }

  get measurementsArray() {
    const arr: IMeasurementProfile[] = Array.from(this.measurements);
    return arr;
  }

  get measurements() {
    return readMeasurements(this.$store);
  }

  get perspectives() {
    return readWoundPerspectives(this.$store);
  }

  get pictures() {
    return readPictures(this.$store);
  }

get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  get pictureB64() {
    return readPicturesURL(this.$store);
  }

  get picsExist() {
    if (this.pictures.length! > 0) {
      return true;
    }
    return false;
  }
  get measExist() {
    if (this.measurements.length! > 0) {
      return true;
    }
    return false;
  }

  get errorMessage() {
    if (!this.measExist && !this.picsExist) {
      return true;
    }
    return false;
  }

  public showDesOrNot(profile: IPicturesProfile) {
    if (profile.description != null || profile.description != "") {
      return false;
    } else {
      return true;
    }
  }

  public measurementByID(id: number) {
    this.measurements.forEach((element) => {
      if (element.id === id) {
        return element;
      }
    });
    var mesDefault: IMeasurementProfile = {
      creation_date: new Date(),
      id: 1,
      task: 3,
    };
    return mesDefault;
  }

  //Was used to check if the text has been changed
  public checkMesUpdate(mes: IMeasurementProfile) {
    if (
      (this.editValueChar === mes.value_char || mes.value_char === undefined) &&
      (this.editValueInt === mes.value_int || mes.value_int === undefined) &&
      mes.value_bool === undefined
    ) {
      return false;
    }
    return true;
  }

  //For printing into the pdf
  public getPerspectiveByID(id: number) {
    const pers = this.perspectives.find((pic) => (pic.id === id));
    if (pers?.description != "" || null) {
      return "Name: " + pers?.name + " \n  Descpription: " + pers?.description;
    }
    return "Name: " + pers?.name;
  }

  public getNameOfPatient() {
    if (this.patient) {
      return this.patient.firstname + " " + this.patient.lastname;
    }
    return "----";
  }

  //For the ui
  public getPerspectiveNameByID(id: number) {
    let indexHelp;
    this.perspectives.forEach((element, index) => {
      if(element.id === id) {
        indexHelp = index;
      }
    });
    const pers = this.perspectives[indexHelp];
    if (pers?.name != "" || null) {
      return "Name: " + pers?.name;
    }
  }

  public async deletePicture(id: number) {
    dispatchDeletePicture(this.$store, id); //deleting it from the db
    commitDeletePicture(this.$store, id); //deleting it from the picture array
    this.redo++; //forcing the commponent to reload
  }

  public readTypeByTaskTable(id: number) {
    const task = readTask(this.$store)(id);
    if (task?.type) {
      return task?.type;
    }
    return "---";
  }

  public readTypeByTask() {
    const task = readTask(this.$store)(this.editTask);
    return task?.type;
  }

  public readNameOfTask() {
    let task = readTask(this.$store)(this.editTask);
    return task?.name;
  }
  public openImage(id: number) {
    this.dialogImage = id;
    this.dialogPicture = true;
  }

  public editMes(mes: IMeasurementProfile) {
    //The values are set for later working with them for saving the new value to the right mes
    this.editMesID = mes.id;
    this.editDate = mes.creation_date;
    this.editTask = mes.task; //Only the task id
    console.log("The task ID is: " + this.editTask);
    this.editMesLabel = "The measurement type is: ";
    this.editMesLabel += this.readTypeByTask();
    if (mes.value_char) {
      this.editValueChar = mes.value_char;
    } else if (mes.value_int) {
      this.editValueInt = mes.value_int;
    } else if (mes.value_bool) {
      this.editValueBool = mes.value_bool;
    }
    this.editType = this.readTypeByTask() as string;
    this.mesDescription = this.readDescription(mes.task);
    this.dialogMesEdit = true;
  }

  public async updateMeasurement() {
    const mes: IMeasurementUpdate = {
      id: this.editMesID,
      type: this.editType,
    };
    if (this.editType == "TEXT") {
      mes.value_char = this.editValueChar;
    } else if (this.editType == "NUMBERSONLY") {
      mes.value_int = this.editValueInt;
    } else if (this.editType == "TRUEFALSE") {
      mes.value_bool = this.editValueBool;
    }
    const wound = readPatientWound(this.$store);
    const payload = await api
      .updateMeasurement(readToken(this.$store), this.editMesID, mes)
      .catch((error) => console.log("Measurement Update failed" + error));
    commitChangeMeasurement(this.$store, mes);
    this.dialogMesEdit = false;
  }

  public async deleteMeasurement(id: number) {
    const payload = await api.deleteMeasurement(readToken(this.$store), id);
    commitDeleteMeasurements(this.$store, id);
  }

  public readValue(mes: IMeasurementUpdate) {
    //only for updating the mes
    if (mes.value_int != undefined) {
      return mes.value_int;
    } else if (mes.value_char != undefined) {
      return mes.value_char;
    } else if (mes.value_bool != undefined) {
      if (mes.value_bool) {
        return true;
      }
      return false;
    }
    return "Value could not be read";
  }

  public readValueProfile(mes: IMeasurementProfile) {
    if (mes.value_int != undefined) {
      return mes.value_int;
    } else if (mes.value_char != undefined) {
      return mes.value_char;
    } else if (mes.value_bool != undefined) {
      if (mes.value_bool) {
        return true;
      }
      return false;
    }
    return "Value could not be read";
  }

  public readDescription(id: number) {
    let task = readTask(this.$store)(id);
    if (task?.description) {
      return task.description;
    }
    return "---";
  }

  public getImage(id: number) {
    //console.log(this.pictureB64[id]);
    // let imgData = "data:image/jpeg;base64," + this.pictureB64[id];
    let imgData: String = this.pictureB64[id];
    if(imgData) {
      let newImg = imgData.replace("dataimage/jpegbase64", "data:image/jpeg;base64,");
      console.log(newImg);
      return newImg;
   }
    

    // let img = new Image()
    // img.onload = function(){
    //   console.log(img.width + ", " + img.height);
    // };
    // img.src = imgData;
    return;
  }

  //Runs but isnt needed
  // public getWidth(id: number) {
  //   let img = this.getImage(id);
  //   console.log("Return value: " + img.width);
  //   return img.width + "px";
  // }

  public beforeCreate() {
    commitMeasurementsClear(this.$store);
    commitSetPicturesClear(this.$store);
    commitSetPictureURLClear(this.$store);
    const day: Date = readVisitDate(this.$store);
    const tasks = readWoundTasks(this.$store);
    var bobo: boolean = true;
    commitSetShowComment(this.$store, false);
    for (var i = 0; i < tasks.length; i++) {
      if (tasks[i].type === "COMMENT") {
        bobo = false;
      }
      for (var j = 0; j < tasks[i].measurement?.length; j++) {
        if (tasks[i].measurement[j]?.creation_date == day && bobo) {
          commitSetMeasurement(this.$store, tasks[i].measurement[j]);
        } else if (tasks[i].measurement[j]?.creation_date == day) {
          commitSetComment(this.$store, tasks[i].measurement[j]);
          commitSetShowComment(this.$store, true);
          bobo = true;
        }
      }
    }
    const perspectives = readWoundPerspectives(this.$store);
    for (var i = 0; i < perspectives.length; i++) {
      // console.log(
      //   "The pictures array has the length of: " +
      //     perspectives[i].pictures.length
      // );
      for (var j = 0; j < perspectives[i].pictures?.length; j++) {
        // console.log("The date: " + perspectives[i].pictures[j]?.date);
        if (perspectives[i].pictures[j]?.date == day) {
          commitSetPicture(this.$store, perspectives[i].pictures[j]);
        }
      }
    }
  }
  public mounted() {
    dispatchGetPictureURL(this.$store);
  }

  //Getting every picture and sizing it for the pdf into 16:9
  public pictureHelper(img: string) {
    var im = new Image();
    im.src = img;
    var w;
    var h;
    let originalWidth = im.width;
    let originalHeight = im.height;
    h = 65;
    let helph = originalHeight/65;
    w = originalWidth/helph;
    var helper: PictureHelper = {width: w, height: h};
    return helper;
  }
  public makeTable() {
    var table = new Array<any>();
    this.measurements.forEach((element) => {
      let arrayElement: string[] = [];
      arrayElement.push(this.readDescription(element.task));
      arrayElement.push(this.readValueProfile(element).toString());
      arrayElement.push(this.readTypeByTaskTable(element.task));
      table.push(Object.values(arrayElement));
    });
    return table;
  }

  public download() {
    var doc = new jsPDF();
    var docName =
      this.patient?.lastname +
      "_wound_" +
      this.wound.id +
      "_" +
      this.day.toString();
    doc.setFontSize(25);
    if (this.patient?.firstname && this.patient.lastname) {
      doc.text(
        "Horse: " + this.patient?.firstname + " " + this.patient?.lastname,
        10,
        15
      );
    } else if (this.patient?.lastname) {
      doc.text("Horse: " + this.patient?.lastname, 10, 15);
    }
    doc.setFontSize(13);
    doc.text("Body Part: " + this.wound.name + "(" + this.wound.id + ")", 10, 23);
    doc.text("Visit date: " + this.day.toString(), 10, 30);
    // if (this.showComment) {
    //   doc.text("General Comment: " + this.comment, 10, 36);
    // }
    let addON = 0;
    // if(this.comment!.length > 70) {
    //   addON += 5 * (this.comment!.length / 70 );
    // }
    if (this.measurementsArray.length > 0) {
      autoTable(doc, {
        head: [["Task Description", "Data", "Type"]],
        body: this.makeTable(),
        headStyles: {
          fillColor: [192, 0, 0],
        },
        startY: 42 + addON,
        theme: "striped",
      });
    }
    let spacer = 32 + (10 * (this.measurementsArray.length + 1)) + addON;
    if(this.measurementsArray.length > 0) {
      spacer += 25;
    }
      if(spacer > 300) {
        doc.addPage();
      }
    for (var i = 1; i < this.pictures.length + 1; i++) {
      //For being able to multiply it without mislocating everything
      var img = this.getImage(i - 1);
      if(img) {
        let helper: PictureHelper = this.pictureHelper(img!);
          doc.addImage(
            img!,
            "JPEG",
            15,
            spacer, 
            helper.width,
            helper.height,
          );
      }
        var text: string = this.getPerspectiveNameByID(this.pictures[i - 1].perspective) as string;
        if(this.pictures[i - 1].description) {
          text += "\nDescription: " + this.pictures[i - 1].description;
        }
        if(this.pictures[i - 1].prepostproces) {
          text += "\nThis picture was taken before the treatment ";
        }
        else {
          text += "\nThis picture was taken after the treatment ";
        }
        doc.text(text, 110, spacer + 5);
      spacer = spacer + 70;
      if((spacer % 300) > 230 && (i) < this.pictures.length ) {
        doc.addPage();
        spacer = 42;
        doc.setFontSize(25);
        if (this.patient?.firstname && this.patient.lastname) {
          doc.text(
          "Horse: " + this.patient?.firstname + " " + this.patient?.lastname,
          10,
          15
          );
        } else if (this.patient?.lastname) {
          doc.text("Horse: " + this.patient?.lastname, 10, 15);
        }
        doc.setFontSize(13);
        doc.text("Body Part: " + this.wound.name + "(" + this.wound.id + ")", 10, 23);
        doc.text("Visit date: " + this.day.toString(), 10, 30);
        // if (this.showComment) {
        //   doc.text("General Comment: " + this.comment, 10, 36);
        // }
      }
    }
    doc.save(docName);
  }
}
